<template>
    <div class="jc-component__range">
        <div class="jc-range" :class="rangeStatus?'success':''">
            <i @mousedown="rangeMove" :class="rangeStatus?successIcon:startIcon" ref="sliderIcon" v-if="clientType==0"/>
            <i @touchmove="phoneRangeMove" :class="rangeStatus?successIcon:startIcon" ref="sliderIcon" v-else/>
            {{rangeStatus?$t('login.sliderSuccessText'):$t('login.sliderStartText')}}
        </div>
    </div>
</template>
<script>
    import { getCurrentInstance,reactive,toRefs,defineComponent,onMounted} from 'vue';
    export default defineComponent({
        props: {
            successFun: {
                type: Function
            },
            successIcon: {
                type: String,
                default: "iconfont icon-chenggong"
            },
            successText: {
                type: String,
                default: "验证成功"
            },
            startIcon: {
                type: String,
                default: "iconfont icon-huakuai"
            },
            startText: {
                type: String,
                default: "拖动滑块到最右边"
            },
            errorFun: {
                type: Function
            },
            status: {
                type: String
            }
        },
        name: "Slider",
        setup(props,context){
            const {proxy}=getCurrentInstance();
            let dataObj=reactive({
                sliderIcon:'',
                rangeStatus:'',
                disX:0,
                clientType:0
            })
            onMounted(()=>{
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                if(flag)dataObj.clientType=1;
            })
            //滑块鼠标按下事件
            const rangeMove=(e)=>{
                let ele = e.target;
                let startX = e.clientX;
                let eleWidth = ele.offsetWidth;
                let parentWidth =  ele.parentElement.offsetWidth;
                let MaxX = parentWidth - eleWidth;
                if(dataObj.rangeStatus){
                    return false;
                }
                document.onmousemove = (e) => {
                    let endX = e.clientX;
                    dataObj.disX = endX - startX;
                    if(dataObj.disX<=0){
                        dataObj.disX = 0;
                    }
                    if(dataObj.disX>=MaxX-eleWidth){
                        dataObj.disX = MaxX;
                    }
                    ele.style.transition = '.1s all';
                    ele.style.transform = 'translateX('+dataObj.disX+'px)';
                    e.preventDefault();
                }
                document.onmouseup = ()=> {
                    if(dataObj.disX !== MaxX){
                        ele.style.transition = '.5s all';
                        ele.style.transform = 'translateX(0)';
                        props.errorFun && props.errorFun();
                    }else{
                        dataObj.rangeStatus = true;
                        if(props.status){
                            proxy.$parent[props.status] = true;
                        }
                        props.successFun && props.successFun();
                    }
                    document.onmousemove = null;
                    document.onmouseup = null;
                }
            }
            const phoneRangeMove=(e1)=>{
                let ele = e1.target;
                let eleWidth = ele.offsetWidth;
                let parentWidth =  ele.parentElement.offsetWidth;
                let MaxX = parentWidth - eleWidth;
                if(dataObj.rangeStatus){
                    return false;
                }
                document.ontouchmove  = (e) => {
                    let endX = e.touches[0].clientX;
                    dataObj.disX = endX;
                    if(dataObj.disX<=0){
                        dataObj.disX = 0;
                    }
                    if(dataObj.disX>=MaxX-eleWidth){
                        dataObj.disX = MaxX;
                    }
                    ele.style.transition = '.1s all';
                    ele.style.transform = 'translateX('+dataObj.disX+'px)';
                }
                document.ontouchend  = ()=> {
                    if(dataObj.disX !== MaxX){
                        ele.style.transition = '.5s all';
                        ele.style.transform = 'translateX(0)';
                        props.errorFun && props.errorFun();
                    }else{
                        dataObj.rangeStatus = true;
                        if(props.status)proxy.$parent[props.status] = true;
                        props.successFun && props.successFun();
                    }
                    document.ontouchmove = null;
                    document.ontouchend = null;
                }
            }
            const resetSlider=()=>{
                dataObj.rangeStatus='';
                dataObj.disX=0;
                dataObj.sliderIcon.style.transform = 'translateX(0)';
            }
            return{
                ...toRefs(dataObj),rangeMove,phoneRangeMove,resetSlider
            }
        }
    });
</script>
<style scoped lang="scss">
    @import "src/style/global";
    .jc-component__range{
        margin-bottom: 8px;
        width: 100%;
        .jc-range{
            background-color: #FFCCCC;
            position: relative;
            transition: 1s all;
            user-select: none;
            color: #333;
            @include flexAlign('',center,center);
            height: 45px; /*no*/
        }
        i{
            position: absolute;
            left: 0;
            width: 60px;/*no*/
            height: 100%;
            color: #919191;
            background-color: #fff;
            border: 1px solid #bbb;
            cursor: pointer;
            @include flexAlign('',center,center);
        }
    }

    .jc-range.success{
        background-color: #7AC23C;
        color: #fff;
    }
    .jc-range.success i{
        color: #7AC23C;
    }
</style>