import VerifyCode from "@/components/utils/VerifyCode.vue";  //数字验证码
import Slider from "@/components/utils/Slider.vue";  //滑块验证码
import loginUtil,{ILoginForm,ILoginDataObj} from "@/views/login/loginUtil";
import {getCurrentInstance, reactive, toRefs, onBeforeMount, onMounted, defineComponent, nextTick, inject, computed} from 'vue';
import {useStore} from "vuex";
let loginForm:ILoginForm={
    usercode: "",
    password: "",
    verifyCode: ""
}
export default defineComponent({
    name: "Login",
    setup(props,context){
        let app = inject('app') as any;
        const appInstant = getCurrentInstance();
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let store = useStore();
        proxy.i18n.global.locale.value = localStorage.getItem('currentLang')?localStorage.getItem('currentLang'):'zh-CN';
        let dataObj:ILoginDataObj=reactive<ILoginDataObj>({
            refMap:new Map(),
            sliderStatus: false,
            yzmType:0,
            backgroundImage:'',
            useYzm: true,
            loginForm:loginForm,
            loginFormRules:utils.UtilPub.commonFormValidRule([{usercode:proxy.$t('login.usercodePlaceholder')},{password:proxy.$t('login.passwordPlaceholder')}]),
            fullscreenLoading:false,
            loginMsg:'',
            otherParams:{
                canRegister:false,
                needMultiLanguage:false,
                currentLang:localStorage.getItem('currentLang')?localStorage.getItem('currentLang'):'zh-CN'
            }
        })
        let loginUtilInst: loginUtil=new loginUtil(proxy,appInstant,dataObj);
        onBeforeMount(()=>{

        })
        onMounted( async()=>{
            await nextTick(async()=>{
                loginUtilInst.checkBrows();
                if (proxy.$socket) proxy.$socket.close();

                sessionStorage.clear();

                await store.dispatch("closeAllVisitedTabsView");
                sessionStorage.removeItem('userLayerData');

                dataObj.refMap.get('usercodeRef').focus();

                await loginUtilInst.loginInit(dataObj);

               if(utils.Tools.isAnimationOpen('loginAnimation')){
                   document.documentElement.style.setProperty('--login-before', proxy.$t('login.beforeContent'));
                   document.documentElement.style.setProperty('--login-after', proxy.$t('login.afterContent'));
                   import('../../style/login/login.scss').then(() => { });
               }else{
                   import('../../style/login/loginNoAnimation.scss');
               }
            })
        })
        const selectOnChange=(newValue:string,selectId:string)=>{
            proxy.i18n.global.locale.value = newValue;
            localStorage.setItem('currentLang',newValue);
            app.helpParam++
        }
        const loginClass=computed( ()=>{
            let loginAnimationFlag:number = localStorage.getItem('loginAnimation')?parseInt(localStorage.getItem('loginAnimation') as string):1;
            return {
                login: true,
                animate__animated: loginAnimationFlag==0,
                animate__fadeInLeft: loginAnimationFlag==0
            };

        })
        return {
            ...toRefs(dataObj),loginUtilInst,selectOnChange,loginClass
        }
    },
    components: {VerifyCode,Slider}
});